import React, { useEffect, useState } from "react";
// import { Link } from "gatsby"
import { handleLogin, isLoggedIn, logout } from "../services/auth";

import { Button, Link } from "gatsby-theme-material-ui";
import Layout from "../layout";
import { navigate } from "gatsby";

import TextField from '@mui/material/TextField';
import { Container } from "@mui/system";
import { Grid, Typography } from "@mui/material";

const Login = () => {
    const [user, setUser] = useState({});
    const [error, setError] = useState(null);

    // const res = handleLogin({username: "mmhuser", password: "123456789"});
    // console.log(isLoggedIn());

    // logout(() => {
    //     console.log('logout')
    // });

    useEffect(() => {
        if(isLoggedIn()) {
            navigate('/admin')
        }

    }, [])

    const handleUser = (e) => {
        // console.log(e?.target?.name)
        setUser({...user, [e?.target?.name]: e?.target?.value})
    }

    const handleLoggin = () => {
        // console.log(user)

        if(user?.username && user?.password) {
            handleLogin(user);
            if(isLoggedIn()) {
                navigate('/admin')
            } else {
                setError("El usuario o la contraseña son incorrectos!.")
            }
        }
    }
    
    return (
        <Layout>
            <Container
                maxWidth="sm"
            >
                <Grid 
                    container
                    rowSpacing={2}
                    sx={{
                        paddingY: 5
                    }}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography
                            variant="h4"
                            component="h1"
                        >
                            Iniciar sesion
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <TextField
                            label="Usuario" 
                            variant="filled" 
                            fullWidth
                            name="username"
                            onChange={handleUser}
                            helperText={error}
                            error={error != null}
                        /> 
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <TextField
                            label="Contraseña"
                            variant="filled" 
                            fullWidth
                            name="password"
                            onChange={handleUser}
                            helperText={error}
                            error={error != null}
                        /> 
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                color: "#fff"
                            }}
                            disabled={!user?.username || !user?.password}
                            onClick={handleLoggin}
                        >Entrar</Button>
                    </Grid>

                </Grid>

            </Container>
        </Layout>
    ) 
}

export default Login
